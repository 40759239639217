<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            A nyaralás szóljon a jó élményekről!
          </h2>
          <p class="hashText">- És adataid biztonságáról!</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Ki ne örülne nyaralás során az ingyen Wi-Fi-nek? A nyílt hálózatok azonban több veszélyt rejtenek, mint gondolnád. Ez talán eszünkbe sem jut vakációnk során, hisz olyankor élvezni akarjuk a szabadidőnket, a programokat, a másokkal töltött időt. Sokunk számára már alapvető, hogy utas- és csomagbiztosítást kötünk az utazás foglalásakor. Azonban egy olyan világban élünk, ahol nem csak attól kell félnünk, hogy a pénztárcánk eltűnik a táskánkból. A telefonunkon tárolt adatainkra és privát dolgainkra legalább annyira kell figyelnünk, mint fizikai értékeinkre.</p>

                <p class="text">
                Pár apró dolgot szem előtt tartva azonban biztonságban élvezhetik adataid is a vakációt!</p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/nyaralas.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Nyílt vs. jelszavas Wi-Fi:</b> Azt tudtad, hogy amit egy nyílt Wi-Fi-n keresztül csinálunk, bárki számára elérhetővé válik? És a bárki úgy értendő, hogy mindenki, aki hozzá akar férni. Bár nem hivatalos módon, de könnyen be lehet lépni azokba az alkalmazásokba, amiket nyílt hálózaton keresztül használunk, vagy látni az elmentett jelszavainkat és adatainkat. Így nyílt Wi-Fi hálózaton semmiképp ne használj banki szolgáltatásokat!</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Laptop vagy telefon?:</b>Az sem mindegy, hogy milyen eszközt használunk nyaralásunk során. Teljesen más biztonsági háttérrel rendelkezik egy laptop, mint egy mai okostelefon, de ami közös bennük, hogy ellopni mindkettőt könnyű. Érdemes legálabb az eszközbe való belépést jelszóval és lehetőség szerint biometrikus azonosítással védeni. De ha biztosra akarsz menni, akkor ajánlott a laptop merevlemezét is lekódolni, illetve a telefon egyes (vagy minden) alkalmazását is személyes azonosítóval ellátni.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Email-es veszélyek:</b> Gyakran lehet találkozni olyan emailekkel, amelyeket a lefoglalt szálláshelyünk nevében küldenek nekünk. Ezek a környéken található, vagy az ottlétünk alatt elérhető látványosságokról, programokról szólnak. Ez egyfajta figyelmesség, úgy érezzük, hogy már a foglalás időpontjában törődnek velünk. Azonban nem árt itt is résen lenni. Ritkábban kapunk a nyaralás időtartama alatt ajánlásokat, hiszen olyankor kevésbé figyeljük az emaileket, gyakran vagyunk offline, és ezt a szállásadónk is tudja. Az effajta gyanús leveleket megnyitás nélkül érdemes törölni, mert általában adathalász linkeket tartalmaznak. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Irány az inkognitó ablak:</b> Sokunk számára kedvelt böngészési mód az inkognitó, hisz az alkalmazás így nem tárolja a megtekintett weboldalakat, belépési adatokat illetve a cookie-kat sem. Könnyebb felmérni vele a piacot, legyen szó szállásról, utazásról, vagy egyéb élményről. Figyelni azonban itt is kell, hiszen az inkognitó mód nem véd meg a nyílt hálózaton keresztül leselkedő veszélyekről. Használni bátran lehet, de nem árt az óvatosság.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Vigyázzunk a pénzünkre:</b> Fizikális bankkártya a zsebünkben, a táskánkban, virtuális kártya a telefonon vagy az okosórán. Egy kis elővigyázatossággal megnehezíthetjük a tolvajok dolgát. Az egyik biztonsági apróság az adatlopás ellen védő tok bankkártyákra, amely blokkolja a paypass-t, így valóban csak a mi tudomásunkkal lehet pénzt leemelni a kártyánkról. Ez azonban a telefonon vagy okosórán található virtuális kártyát nem védi, így a másik óvintézkedés amit tehetünk, hogy mindig bezárjuk a pénzügyi applikációkat, és nem hagyjuk, hogy a háttérben fussanak.</p>
                </li>
              </ul>
            </p>
            <p class="text">Az előbb felsorolt okosságok figyelembevételével a minimálisra csökkenthetjük az adatlopás kockázatát. Ettől függetlenül ébernek kell maradnunk, ha zökkenőmentes időt szeretnénk eltölteni nyaralásunk során.</p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "A nyaralás szóljon a jó élményekről! ", 
        desc: "Ki ne örülne nyaralás során az ingyen Wi-Fi-nek? A nyílt hálózatok azonban több veszélyt rejtenek, mint gondolnád.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "A nyaralás szóljon a jó élményekről!",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Ki ne örülne nyaralás során az ingyen Wi-Fi-nek? A nyílt hálózatok azonban több veszélyt rejtenek, mint gondolnád."        
    })
  }
}
</script>